import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import GroupIcon from "@mui/icons-material/Group";
import ArticleIcon from "@mui/icons-material/Article";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import OrderTable from "../order/OrderTable";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../../hooks/useAuth";
import OrderList from "../order/OrderList";
import ComputerIcon from "@mui/icons-material/Computer";
import DashboardIcon from "@mui/icons-material/Dashboard";

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
});

export default function MobileLayout() {
  const { onLogout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  async function handleNewOrder() {
    navigate("/mobile/new-order");
  }

  function handleLogout() {
    onLogout();
    navigate("/login");
  }

  React.useEffect(() => {
    if (location.pathname === "/mobile") {
      navigate("/mobile/dashboard");
    }
  }, [navigate, location]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Box id="home-background" sx={{ pb: "100%", pt: "50px", m: "15px" }}>
        <Outlet />
      </Box>
      <AppBar position="fixed" color="primary" sx={{ bottom: "auto", top: 0 }}>
        <Toolbar>
          {location.pathname !== "/mobile/dashboard" && (
            <IconButton
              color="inherit"
              onClick={() => navigate("/mobile/dashboard")}
            >
              <DashboardIcon />
            </IconButton>
          )}
          <Box flexGrow={1}></Box>
          <IconButton
            color="inherit"
            onClick={() => {
              navigate(location.pathname.replace("mobile", "desktop"));
            }}
          >
            <ComputerIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleLogout();
            }}
            color="inherit"
            aria-label="open drawer"
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          {/* <IconButton color="inherit" aria-label="open drawer">
            <GroupIcon />
          </IconButton> */}
          <StyledFab
            color="secondary"
            aria-label="add"
            onClick={() => {
              handleNewOrder();
            }}
          >
            <AddIcon />
          </StyledFab>
          <Box sx={{ flexGrow: 1 }} />
          {/* <IconButton color="inherit" aria-label="open drawer">
            <ArticleIcon />
          </IconButton>
          <IconButton color="inherit">
            <SearchIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
