import { IOrder as IOrder, IOrderRequest } from "../types/order";

const order: IOrderRequest = {
  customer: {
    name: "",
    email: "",
    web: "",
    address: {
      name: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    phone: {
      private: "",
      business: "",
      mobile: "",
    },
  },
  solar_details: {
    power_storage: false,
    power_storage_size: "",
    yearly_consumption: "",
    system_size: "",
    photos_object: [],
    comment_object: "",
    photos_system: [],
    comment_system: "",
  },
  document_ids: null,
  articles: null,
  locked: true,
  version: ""
};

export function NewOrderRequest(): IOrderRequest {
  return order;
}

export function GetRequestFromOrder(order: IOrder): IOrderRequest {
  return {
    id: order.id,
    customer_id: order.customer_id,
    customer: {
      name: order?.customer?.name,
      email: order?.customer?.email,
      web: order.customer?.web,
      address: {
        name: order.customer?.address.name,
        street: order.customer?.address.street,
        city: order.customer?.address.city,
        state: order.customer?.address.state,
        zip: order.customer?.address.zip,
        country: order.customer?.address.country,
      },
      phone: {
        private: order.customer?.phone.private,
        business: order.customer?.phone.business,
        mobile: order.customer?.phone.mobile,
      },
    },
    solar_details: {
      power_storage: order.solar_details.power_storage,
      power_storage_size: order.solar_details.power_storage_size,
      yearly_consumption: order.solar_details.yearly_consumption,
      system_size: order.solar_details.system_size,
      photos_object: order.solar_details.photos_object,
      comment_object: order.solar_details.comment_object,
      photos_system: order.solar_details.photos_system,
      comment_system: order.solar_details.comment_system,
    },
    document_ids: order.document_ids,
    articles: order.articles,
    locked: order.locked,
    version: order.version
  };
}
