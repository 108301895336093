import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ICustomer } from "../../solar-api/types/order";
import { debounce } from "../../solar-api/debounce";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { sliceOrder } from "../../features/order";
import { orderData } from "../../solar-api/data";
import { solarService } from "../../solar-api/service";
import { Stack } from "@mui/material";

const initCustomer = {
  id: "",
  name: "",
  email: "",
  web: "",
  address: {
    name: "",
    state: "",
    city: "",
    street: "",
    zip: "",
    country: "",
  },
  phone: {
    mobile: "",
    business: "",
    private: "",
  },
  version: "",
  updated_at: new Date(),
  created_at: new Date(),
};
interface NewCustomerProps {
  customer: ICustomer;
  onChange: (customer: ICustomer) => void;
}

export default function NewCustomer(props: NewCustomerProps) {
  function handleChangeCustomer(customer: ICustomer) {
    props.onChange(customer);
  }

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 2, width: "50ch" },
      }}
      noValidate
      autoComplete="off"
      display={"flex"}
      justifyContent={"center"}
      width={"100%"}
      mt={2}
    >
      <Stack id="stack-new-customer" spacing={2}>
        <TextField
          name="name"
          id="customer-name"
          label="Name"
          variant="filled"
          fullWidth
          value={props.customer.name}
          onChange={(e) =>
            handleChangeCustomer({ ...props.customer, name: e.target.value })
          }
        />
        <TextField
          name="address"
          id="customer-address"
          label="Adresse"
          variant="filled"
          value={props.customer.address.street}
          onChange={(e) =>
            handleChangeCustomer({
              ...props.customer,
              address: { ...props.customer.address, street: e.target.value },
            })
          }
        />
        <TextField
          name="zip"
          id="customer-zip"
          label="Postleitzahl"
          variant="filled"
          type={"tel"}
          value={props.customer.address.zip}
          onChange={(e) =>
            handleChangeCustomer({
              ...props.customer,
              address: { ...props.customer?.address, zip: e.target.value },
            })
          }
        />
        <TextField
          name="phone"
          id="customer-phone"
          label="Telefon"
          variant="filled"
          type={"tel"}
          value={props.customer.phone.private}
          onChange={(e) =>
            handleChangeCustomer({
              ...props.customer,
              phone: { ...props.customer?.phone, private: e.target.value },
            })
          }
        />
        {/* TODO: E-Mail validation */}
        <TextField
          name="email"
          id="customer-email"
          label="E-Mail"
          variant="filled"
          value={props.customer.email}
          onChange={(e) =>
            handleChangeCustomer({
              ...props.customer,
              email: e.target.value,
            })
          }
        />
      </Stack>
    </Box>
  );
}
