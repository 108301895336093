import React, { CSSProperties, useEffect, useState } from "react";
import {
  Box,
  CssBaselineProps,
  Divider,
  Paper,
  SxProps,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";
import { useParams } from "react-router-dom";
import { solarService } from "../../solar-api/service";
import { IOrder } from "../../solar-api/types";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { IPhoto } from "../../solar-api/types/photo";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { styled } from "@mui/material/styles";
import { useDevice } from "../../hooks/useDevice";

interface IInfoCardProps {
  id?: string;
  children: React.ReactNode;
}

const InfoCard = ({ children, id, ...rest }: IInfoCardProps) => {
  return (
    <Box id={id ?? "info-card"} mt={2} maxWidth={530} minWidth={300}>
      {children}
    </Box>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface IImagaeSorcues {
  [key: string]: string;
}

interface IInfoFieldProps {
  label?: string;
  value?: string;
  unit?: string;
}
const textStyles: SxProps = {
  m: 1,
};

const InfoField = (props: IInfoFieldProps) => {
  return (
    <Table>
      <StyledTableRow sx={{ border: 0 }}>
        <TableCell>
          <Typography>{props.label ?? ""} {props.unit ? `(${props.unit})` : ""}:</Typography>
        </TableCell>
        <TableCell align="right">
          {props.value ?? ""}
        </TableCell>
      </StyledTableRow>
    </Table>
  );
};

const Order = () => {
  const [order, setOrder] = useState<IOrder>();
  const [photoView, setPhotoView] = useState<boolean>(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string>("");
  const [objectPhotos, setObjectPhotos] = useState<IImagaeSorcues>({});
  const [systemPhotos, setSystemPhotos] = useState<IImagaeSorcues>({});

  const { height, width } = useWindowDimensions();
  const device = useDevice();

  async function getPhotoSrc(
    photos: string[],
    cb: (imageSources: IImagaeSorcues) => void
  ) {
    let sources: IImagaeSorcues = {};
    for (const photo of photos) {
      try {
        const pUrl = await solarService.ServePhotoPreview(photo);
        sources = { ...sources, [photo]: pUrl };
      } catch (error) {
        console.log("Fehler bei der Erstellung der Foto URL");
        console.log(error);
      }
    }
    cb(sources);
  }

  const params = useParams();

  useEffect(() => {
    getOrder();
  }, []);

  useEffect(() => {
    if (order?.solar_details.photos_object) {
      getPhotoSrc(order?.solar_details.photos_object, (imageSource) => {
        setObjectPhotos({
          ...imageSource,
        });
      });
    }

    if (order?.solar_details.photos_system) {
      getPhotoSrc(order?.solar_details.photos_system, (imageSource) => {
        setSystemPhotos({
          ...imageSource,
        });
      });
    }
  }, [order]);

  async function getOrder() {
    if (!params.id) return;
    const res = await solarService.GetOrder(params.id);
    if (res.state === "error") {
      console.error(res.message);
    } else {
      setOrder(res.data);
    }
  }

  async function selectPhoto(id: string) {
    const res = await solarService.ServePhoto(id);
    setSelectedPhoto(res);
    setTimeout(() => {
      setPhotoView(true);
    }, 250);
  }

  return (
    // ==== || Outer || ===
    <Box
      id="order-container"
      display={"flex"}
      flexDirection="column"
      justifyContent={"center"}
      alignItems={device === "mobile" ? "center" : "normal"}
      width={"100%"}
    >
      <InfoCard>
        <Paper sx={{ p: 1 }}>
          <Typography variant="h5">Auftrag</Typography>
          <Box mt={1}>
            <InfoField label="Auftragsnummer" value={order?.name} />
            <InfoField
              label="Datum"
              value={moment(order?.created_at).format("DD.MM.YYYY")}
            />
          </Box>
        </Paper>
      </InfoCard>
      {/* === || Customer || === */}
      <InfoCard>
        <Paper sx={{ p: 1 }}>
          <Typography variant="h5">Kunde</Typography>
          <Box mt={1}>
            <InfoField label="Name" value={order?.customer?.name} />
            <InfoField
              label="Adresse"
              value={order?.customer?.address.street}
            />
            <InfoField
              label="Postleitzahl"
              value={order?.customer?.address.zip}
            />
            <InfoField label="Telefon" value={order?.customer?.phone.private} />
            <InfoField label="E-Mail" value={order?.customer?.email} />
          </Box>
        </Paper>
      </InfoCard>
      <InfoCard>
        <Paper sx={{ p: 1 }}>
          <Typography variant="h5">Technik</Typography>
          <Box mt={1}>
            <InfoField
              label="Anlagengröße"
              value={order?.solar_details.system_size}
              unit="kW/h"
            />

            <InfoField
              label="Jährlicherverbrauch"
              value={order?.solar_details.yearly_consumption}
              unit="kW/h"
            />

            <InfoField
              label="Mit Batteriespeicher"
              value={order?.solar_details.power_storage ? "Ja" : "Nein"}
            />
            {order?.solar_details.power_storage && (
              <>
                <InfoField
                  label="Batteriegröße"
                  value={order?.solar_details.power_storage_size}
                  unit="kW/h"
                />
              </>
            )}

            <InfoField
              label="Anmerkung Objekt"
              value={order?.solar_details.comment_object}
            />

            <InfoField
              label="Anmerkung Elektrik"
              value={order?.solar_details.comment_system}
            />
          </Box>
        </Paper>
      </InfoCard>

      {/* === || Fotos Objekte || === */}
      <InfoCard>
        <Paper sx={{ p: 1 }}>
          <Typography variant="h5">Fotos Objekte</Typography>
          {Object.keys(objectPhotos).length === 0 && (
            <Typography variant="body1">Keine Fotos vorhanden</Typography>
          )}
          {Object.keys(objectPhotos).length > 0 && (
            <Box mt={1}>
              <ImageList>
                {Object.keys(objectPhotos).map((p) => {
                  return (
                    <ImageListItem
                      key={p}
                      onClick={() => selectPhoto(p)}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <img src={objectPhotos[p]} alt="" srcSet="" />
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </Box>
          )}
        </Paper>
      </InfoCard>

      {/* === || Fotos Elektrik || === */}
      <InfoCard>
        <Paper sx={{ p: 1 }}>
          <Typography variant="h5">Fotos Elektrik</Typography>
          {Object.keys(systemPhotos).length === 0 && (
            <Typography variant="body1">Keine Fotos vorhanden</Typography>
          )}
          {Object.keys(systemPhotos).length > 0 && (
            <Box mt={1}>
              <ImageList sx={{ minWidth: 300, height: "450" }}>
                {Object.keys(systemPhotos).map((p) => {
                  return (
                    <ImageListItem
                      key={p}
                      onClick={() => selectPhoto(p)}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <img src={systemPhotos[p]} alt="" srcSet="" />
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </Box>
          )}
        </Paper>
      </InfoCard>
      <Backdrop open={photoView} onClick={() => setPhotoView(false)}>
        <Box
          component="img"
          sx={{
            height: height * 0.8,
          }}
          alt=""
          src={selectedPhoto}
        />
      </Backdrop>
    </Box>
  );
};

export default Order;
