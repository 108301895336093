
const defaultConfig = {
	API_URL: "http://localhost:8081",
}

function getConfig() {
	if (process.env.NODE_ENV !== 'production'){
		return defaultConfig;
	}

	if (window._env_ && window._env_.debug) {
		return {
			...defaultConfig, // falls irgendwann noch mehr darin steht
			API_URL: window._env_.DEBUG_URL,
		}
	}

	if (window._env_ && window._env_.API_URL) {
		return {
			...defaultConfig, // falls irgendwann noch mehr darin steht
			API_URL: window._env_.API_URL,
		}
	}
	return defaultConfig;
}


export const config = getConfig();

