import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Stack } from "@mui/material";
import { ISolarDetails } from "../../solar-api/types";

interface ISolorDetailsProps {
  solarDetails: ISolarDetails;
  onChange: (SolarDetails: ISolarDetails) => void;
}

export default function NewSolarDetails(props: ISolorDetailsProps) {
  const [powerStorage, setPowerStorage] = React.useState(false);

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 2, width: "50ch" },
      }}
      noValidate
      autoComplete="off"
      display={"flex"}
      justifyContent={"center"}
      width={"100%"}
      mt={2}
    >
      <FormGroup>
        <Stack id="stack-new-customer" spacing={2}>
          <TextField
            id="yearly-consumption"
            type={"tel"}
            label="Jahresverbrauch (kW/h)"
            variant="filled"
            value={props.solarDetails.yearly_consumption}
            onChange={(e) =>
              props.onChange({
                ...props.solarDetails,
                yearly_consumption: e.target.value,
              })
            }
          />
          <TextField
            id="system-size"
            type={"tel"}
            label="Anlagengröße (kW/h)"
            variant="filled"
            value={props.solarDetails.system_size}
            onChange={(e) =>
              props.onChange({
                ...props.solarDetails,
                system_size: e.target.value,
              })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.solarDetails.power_storage}
                onChange={(e) =>
                  props.onChange({
                    ...props.solarDetails,
                    power_storage: e.target.checked,
                  })
                }
              />
            }
            label="Batteriespeicher"
          />
          {props.solarDetails.power_storage && (
            <TextField
              id="power-storage-size"
              type={"tel"}
              label="Größe Batteriespeicher (kWh)"
              variant="filled"
              value={props.solarDetails.power_storage_size}
              onChange={(e) =>
                props.onChange({
                  ...props.solarDetails,
                  power_storage_size: e.target.value,
                })
              }
            />
          )}
        </Stack>
      </FormGroup>
    </Box>
  );
}
