import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { logOut } from "../features/auth/authSlice";
import { solarService } from "../solar-api/service";
import { APIResponse } from "../solar-api/types";
import { ISession } from "../solar-api/types/session";
import { useAppDispatch } from "../store/hooks";

interface IAuthContext {
  token: string;
  onLogin: (
    email: string,
    password: string
  ) => Promise<APIResponse<ISession | null>>;
  onLogout: () => void;
  verifySession: () => Promise<boolean>;
}

export const AuthContext = React.createContext<IAuthContext>(
  {} as IAuthContext
);

interface IAuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [token, setToken] = React.useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogin = async (
    email: string,
    password: string
  ): Promise<APIResponse<ISession | null>> => {
    const response = await solarService.GetSession(email, password);

    if (response.data) {
      localStorage.setItem("jwt", response.data.access_token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.access_token}`;
      setToken(response.data.access_token);
    } else {
      console.log("Login failed");
    }

    return response;
  };

  const handleLogout = () => {
    localStorage.removeItem("jwt")
    delete axios.defaults.headers.common["Authorization"];
    setToken("");
    dispatch(logOut)
    navigate("/login", { replace: true });
  };

  async function verifySession(): Promise<boolean> {
    const accessToken = token ? token : localStorage.getItem("jwt");
    if (accessToken) {
      const res =  await solarService.VerifyToken(accessToken);
      if (res) {
        setToken(accessToken);
        return true;
      }
    }
    setToken("");
    return false
  }


  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
    verifySession: verifySession
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
