import { Box } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const DesktopLayout = () => {
  const { onLogout } = useAuth();
  const navigate = useNavigate();

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Box flex={1}></Box>
        <button
          onClick={() => {
            onLogout();
          }}
        >
          Logout
        </button>
      </div>
      <Box display={"flex"}>
        <Box flex={0}>
          <ul>
            <li>
              <NavLink to="/desktop/dashboard">Dashboard</NavLink>
            </li>
            <li>
              <NavLink to="/desktop/orders">Aufträge</NavLink>
            </li>
            <li>
              <NavLink to="/mobile/new-order">Neuer Auftrag</NavLink>
            </li>
            <li>
              <NavLink to="/mobile/dashboard">Mobile Ansicht</NavLink>
            </li>
          </ul>
        </Box>
        <Box flex={1} p={2}>
          <Outlet />
        </Box>
      </Box>
    </div>
  );
};

export default DesktopLayout;
