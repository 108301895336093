import React, { useState } from "react";
import { API_URL, UploadFile } from "../../solar-api/service/solar.service";
import { APIResponse } from "../../solar-api/types";
import { IPhoto } from "../../solar-api/types/photo";

export function useUpload() {
  const [progress, setProgress] = useState<number>();

  async function handleUpload(
    event: React.ChangeEvent<HTMLInputElement>,
    orderNumber: string
  ): Promise<APIResponse<IPhoto>> {
    const file = event.target.files?.item(0);
    const formData = new FormData();
    formData.append("file", file as Blob);
    formData.append("order_number", orderNumber);

    const url = `${API_URL}/api/v1/solar/photo`;
    return await UploadFile<IPhoto>(url, formData, (event: any) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    });
  }

  return {
    upload: handleUpload,
    progress: progress,
  };

}
