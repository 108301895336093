import { Alert, Box, Button, Stack, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useDevice } from "../../hooks/useDevice";

const Login = () => {
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");

  const { onLogin } = useAuth();
  const device = useDevice();
  const navigate = useNavigate();

  async function handleLogin() {
    const response = await onLogin(email, password);
    if (response.state === "error") {
      setError(
        response.message === "unauthorized"
          ? "Falscher Benutzer oder falsches Passwort"
          : "Es ist ein unerwarteter Fehler aufgetreten"
      );
    } else {
      console.log("Login successful");
      const next = device === "desktop" ? "desktop/dashboard" : "mobile";
      // navigate(`/${next}`, { replace: true });
      navigate(`/`, { replace: true });
    }
  }

  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"center"}
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Stack justifyContent={"center"} alignItems="center">
          <h1>Login</h1>
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            required
            id="outlined-email"
            label="E-Mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />
          <TextField
            id="outlined-password-input"
            required
            label="Password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />
          <Button fullWidth variant="contained" onClick={handleLogin}>
            Login
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default Login;
