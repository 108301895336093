import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { solarService } from "../../solar-api/service";
import { IOrder } from "../../solar-api/types";
import DeleteIcon from "@mui/icons-material/Delete";
import OrderIcon from "@mui/icons-material/Description";
import DecisionDialog from "../components/DecisionDialog";
import { useNavigate } from "react-router-dom";

interface IOrderListProps {
  count?: number;
}

const OrderList = (props: IOrderListProps) => {
  const [orders, setOrders] = useState<IOrder[]>();
  const [selectedOrder, setSelectedOrder] = useState<IOrder>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [orderCount, setOrderCount] = useState<number>(
    props.count ? props.count : 5
  );

  const navigate = useNavigate();

  async function getOrders() {
    const res = await solarService.GetAllOrders({
      sort: "created_at",
      order: "desc",
    });
    if (res.state === "success") {
      setOrders(res.data);
    }
  }
  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    setOrderCount(props.count ? props.count : 5);
  }, [props.count]);

  // create a function that returns the date in a readable format with moment
  const formatDate = (date: Date) => {
    return moment(date).format("DD.MM.YYYY");
  };

  function handleOpenDeleteDialog(order: IOrder) {
    setSelectedOrder(order);
    setDialogOpen(true);
  }

  function handleCloseDeleteDialog() {
    setSelectedOrder(undefined);
    setDialogOpen(false);
  }

  async function handleDeleteOrder() {
    if (!selectedOrder) return;
    const res = await solarService.DeleteOrder(selectedOrder?.id);
    if (res.state === "error") {
      console.log(res.message);
    }
    await getOrders();
    setDialogOpen(false);
  }

  return (
    <Box
      sx={{
        m: 1,
      }}
    >
      <Paper
        sx={{
          p: 2,
        }}
      >
        {orders?.length === 0 && (
          <Typography>Keine Aufträge vorhanden</Typography>
        )}
        <List>
          {orders &&
            orders.slice(0, orderCount).map((order, index) => {
              return (
                <ListItem
                  key={order.id}
                  secondaryAction={
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(order)}
                      edge="end"
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/mobile/orders/" + order.name)}
                  >
                    <Avatar>
                      <OrderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={order.name}
                    secondary={
                      order.customer?.name +
                      " - " +
                      formatDate(order.created_at)
                    }
                  />
                </ListItem>
              );
            })}
        </List>
      </Paper>
      <DecisionDialog
        open={dialogOpen}
        title="Auftrag löschen"
        content="Bist du dir wirklich sicher Julian?"
        textOk="Löschen"
        textClose="Abbrechen"
        onClose={() => handleCloseDeleteDialog()}
        onOk={() => handleDeleteOrder()}
      />
    </Box>
  );
};

export default OrderList;
