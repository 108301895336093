import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { solarService } from "../../solar-api/service";
import { IOrder } from "../../solar-api/types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { Box, Button, IconButton } from "@mui/material";
import DecisionDialog from "../components/DecisionDialog";
import DeleteIcon from "@mui/icons-material/Delete";

const bxStyles: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  margin: 2,
};

const OrderTable = () => {
  const [orders, setOrders] = useState<IOrder[]>();

  const [selectedOrder, setSelectedOrder] = useState<IOrder>();
  const [dialogOpen, setDialogOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getOrders();
  }, []);

  async function getOrders() {
    const res = await solarService.GetAllOrders();
    if (res.state === "error") {
      console.error(res.message);
    } else {
      setOrders(res.data);
    }
  }

  function handleOpenDeleteDialog(order: IOrder) {
    setSelectedOrder(order);
    setDialogOpen(true);
  }

  function handleCloseDeleteDialog() {
    setSelectedOrder(undefined);
    setDialogOpen(false);
  }

  async function handleDeleteOrder() {
    if (!selectedOrder) return;
    const res = await solarService.DeleteOrder(selectedOrder?.id);
    if (res.state === "error") {
      console.log(res.message);
    }
    await getOrders();
    setDialogOpen(false);
  }

  return (
    <Box sx={bxStyles}>
      <TableContainer component={Paper} sx={{ height: 400 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Auftrag</TableCell>
              <TableCell>Kunde</TableCell>
              <TableCell>Anlagengröße</TableCell>
              <TableCell>Datum</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders &&
              orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell 
                  onClick={() => navigate("/desktop/orders/"+ order.name)}
                  sx={{
                    cursor: 'pointer'
                  }}
                  component="th" scope="order">
                    {order.name}
                  </TableCell>
                  <TableCell>{order.customer?.name}</TableCell>
                  <TableCell>{order.solar_details.system_size}</TableCell>
                  <TableCell>
                    {moment(order.created_at).format("DD.MM.YYYY")}
                  </TableCell>
                  <TableCell>
                    {order.locked ? "in Bearbeitung" : "Angelegt"}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(order)}
                      edge="end"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DecisionDialog
        open={dialogOpen}
        title="Auftrag löschen"
        content="Bist du dir wirklich sicher Julian?"
        textOk="Löschen"
        textClose="Abbrechen"
        onClose={() => handleCloseDeleteDialog()}
        onOk={() => handleDeleteOrder()}
      />
    </Box>
  );
};

export default OrderTable;
