import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

import PersonIcon from '@mui/icons-material/Person';
import HouseIcon from '@mui/icons-material/House';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import NewCustomer from '../components/NewCustomer';
import NewSolarDetails from '../components/NewSolarDetails';
import { ICustomer, IOrder } from '../../solar-api/types/order';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { sliceOrder } from '../../features/order';
import { orderData } from '../../solar-api/data';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { solarService } from '../../solar-api/service';
import { debounce } from '../../solar-api/debounce';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISolarDetails } from '../../solar-api/types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import NewObjectPhotos from '../components/NewObjectPhotos';
import { IPhoto } from '../../solar-api/types/photo';
import NewSystemPhotos from '../components/NewSystemPhotos';

export default function NewOrder() {
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState<IOrder>();
  const [value, setValue] = React.useState(0);
  const [view, setView] = React.useState('customer');
  const [loading, setLoading] = React.useState(true);
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);

  const [photosObject, setPhotosObject] = React.useState<IPhoto[]>([]);
  const [photosSystem, setPhotosSystem] = React.useState<IPhoto[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isInit = useAppSelector((state) => state.order.isInit);

  React.useEffect(() => {
    initOrder();
  }, []);

  async function createNewOrder(){
    const payload = orderData.NewOrderRequest();
    const resp = await solarService.CreateOrder(payload);
    if (resp.data) {
      localStorage.setItem('current_order', resp.data.id);
      setOrder(resp.data);
      setLoading(false);
      dispatch(sliceOrder.initOrder());
    } else {
      setLoading(false);
    }
  }

  async function initOrder() {
    const currentOrder = localStorage.getItem('current_order');
    if (currentOrder) {
      const res = await solarService.GetOrder(currentOrder);
      if (res.data) {
        setOrder(res.data);
        setLoading(false);
        dispatch(sliceOrder.initOrder());
      } else {
        dispatch(sliceOrder.deInitOrder());
        await createNewOrder();
      }
    } else {
        dispatch(sliceOrder.deInitOrder());
        await createNewOrder();
    }
  }

  async function updateOrder(order: IOrder) {
    const payload = orderData.GetRequestFromOrder({ ...order, locked: true });
    const res = await solarService.UpdateOrder(payload);
    if (res.status >= 300) {
      console.log(res.message);
    }
  }

  const debouncedOnChange = React.useCallback(
    debounce((order: IOrder) => {
      updateOrder(order);
    }, 2500),
    []
  );

  function handleChangeCustomer(customer: ICustomer) {
    setOrder({ ...order, customer: customer } as IOrder);
    const t = debouncedOnChange({ ...order, customer: customer } as IOrder);
    setTimer(t as unknown as NodeJS.Timeout);
  }

  function handleChangeSolarDetails(solarDetails: ISolarDetails) {
    setOrder({ ...order, solar_details: solarDetails } as IOrder);
    const t = debouncedOnChange({
      ...order,
      solar_details: solarDetails,
    } as IOrder);
    setTimer(t as unknown as NodeJS.Timeout);
  }

  function handleChangeObjectComment(comment: string) {
    if (!order) return;

    const payload: IOrder = {
      ...order,
      solar_details: {
        ...order.solar_details,
        comment_object: comment,
      },
    };
    setOrder(payload);
    const t = debouncedOnChange(payload);
    setTimer(t as unknown as NodeJS.Timeout);
  }

  function handleAddPhotoObject(photo: IPhoto) {
    if (!order) return;

    setPhotosObject([...photosObject, photo]);
    const payload: IOrder = {
      ...order,
      solar_details: {
        ...order.solar_details,
        photos_object: [...order.solar_details.photos_object, photo.id],
      },
    };

    setOrder({ ...payload } as IOrder);
    updateOrder({ ...payload } as IOrder);
  }

  function handleDeletePhotoObject(photo: IPhoto) {
    if (order) {
      let photos = [...photosObject];
      let photoIds = [...order.solar_details.photos_object];
      const index = photoIds.indexOf(photo.id);

      if (index > -1) {
        photoIds.splice(index, 1);
        photos.splice(index, 1);
      }

      setPhotosObject([...photos]);
      const payload: IOrder = {
        ...order,
        solar_details: {
          ...order.solar_details,
          photos_object: photoIds,
        },
      };

      solarService.DeletePhoto(photo.id);
      updateOrder({ ...payload } as IOrder);
      setOrder({ ...payload } as IOrder);
    }
  }

  function handleChangeSystemComment(comment: string) {
    if (!order) return;

    const payload: IOrder = {
      ...order,
      solar_details: {
        ...order.solar_details,
        comment_system: comment,
      },
    };
    setOrder(payload);
    const t = debouncedOnChange(payload);
    setTimer(t as unknown as NodeJS.Timeout);
  }

  function handleAddPhotoSystem(photo: IPhoto) {
    if (!order) return;

    setPhotosSystem([...photosSystem, photo]);
    const payload: IOrder = {
      ...order,
      solar_details: {
        ...order.solar_details,
        photos_system: [...order.solar_details.photos_system, photo.id],
      },
    };

    setOrder({ ...payload } as IOrder);
    updateOrder({ ...payload } as IOrder);
  }

  function handleDeletePhotoSystem(photo: IPhoto) {
    if (order) {
      let photos = [...photosSystem];
      let photoIds = [...order.solar_details.photos_system];
      const index = photoIds.indexOf(photo.id);

      if (index > -1) {
        photoIds.splice(index, 1);
        photos.splice(index, 1);
      }

      setPhotosSystem([...photos]);
      const payload: IOrder = {
        ...order,
        solar_details: {
          ...order.solar_details,
          photos_system: photoIds,
        },
      };

      solarService.DeletePhoto(photo.id);
      updateOrder({ ...payload } as IOrder);
      setOrder({ ...payload } as IOrder);
    }
  }

  async function handleSave() {
    if (order && order.id) {
      const payload = orderData.GetRequestFromOrder({
        ...order,
        locked: false,
      });

      await solarService.UpdateOrder(payload);
      clearTimeout(timer as unknown as NodeJS.Timeout);
      navigate('/mobile', { replace: true });
      dispatch(sliceOrder.deInitOrder());
    }
  }

  function handleClose() {
    clearTimeout(timer as unknown as NodeJS.Timeout);
    if (order && order.id) {
      solarService.DeleteOrder(order?.id);
    }
    dispatch(sliceOrder.deInitOrder());
    navigate('/mobile', { replace: true });
  }

  function renderView(v: string) {
    if (!order || !order.customer) {
      return <div>loading</div>;
    }
    switch (v) {
      case 'customer':
        return (
          <NewCustomer
            onChange={handleChangeCustomer}
            customer={order.customer}
          />
        );
      case 'solar':
        return (
          <NewSolarDetails
            onChange={handleChangeSolarDetails}
            solarDetails={order.solar_details}
          />
        );
      case 'house':
        return (
          <NewObjectPhotos
            orderNumber={order.name}
            photos={photosObject}
            onAddPhoto={handleAddPhotoObject}
            onDeletePhoto={handleDeletePhotoObject}
            onChangeComment={handleChangeObjectComment}
            photos_object={order.solar_details.photos_object}
            comment_object={order.solar_details.comment_object}
          />
        );
      case 'electric':
        return (
          <NewSystemPhotos
            orderNumber={order.name}
            photos={photosSystem}
            onAddPhoto={handleAddPhotoSystem}
            onDeletePhoto={handleDeletePhotoSystem}
            onChangeComment={handleChangeSystemComment}
            photos_system={order.solar_details.photos_system}
            comment_system={order.solar_details.comment_system}
          />
        );
    }
  }

  return (
    <Box sx={{ pb: 7, pt: '55px' }}>
      <AppBar
        position='fixed'
        color='primary'
        sx={{ bottom: 'auto', top: 0 }}>
        <Toolbar>
          <Typography>Neuer Auftrag</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            onClick={() => setOpen(true)}
            color='inherit'
            aria-label='open drawer'>
            <CloseIcon />
          </IconButton>
          <IconButton
            onClick={handleSave}
            color='inherit'
            aria-label='open drawer'>
            <DoneIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <CssBaseline />
      {order && !loading && renderView(view)}
      {loading && <div>loading</div>}
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        elevation={3}>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}>
          <BottomNavigationAction
            icon={<PersonIcon />}
            onClick={() => setView('customer')}
          />
          <BottomNavigationAction
            icon={<SolarPowerIcon />}
            onClick={() => setView('solar')}
          />
          <BottomNavigationAction
            icon={<HouseIcon />}
            onClick={() => setView('house')}
          />
          <BottomNavigationAction
            icon={<ElectricalServicesIcon />}
            onClick={() => setView('electric')}
          />
        </BottomNavigation>
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Abbrechen'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>Der Auftrag wird unwiederruflich gelöscht.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Zurück</Button>
          <Button
            onClick={handleClose}
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
