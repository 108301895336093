import {
  createSlice,
  nanoid,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ISession } from "../../solar-api/types/session";
import { RootState } from "../../store/store";

// Define a type for the slice state
interface AuthState {
  isLoggedIn: boolean;
  token: string;
  expirationTime: string;
  logoutTimerId: any | null;
}

// Define the initial state using that type
const initialState: AuthState = {
  isLoggedIn: false,
  token: "",
  expirationTime: "",
  logoutTimerId: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<ISession>) => {
      state.isLoggedIn = true;
      state.expirationTime = action.payload.expires;
      state.token = action.payload.access_token;
      if (action.payload.logoutTimerId) {
        state.logoutTimerId = action.payload.logoutTimerId;
      }
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.expirationTime = "";
      state.token = "";
      clearTimeout(state.logoutTimerId);
      state.logoutTimerId = null;
    },
  },
});

export const { logIn, logOut } = authSlice.actions;

export const selectToken = (state: RootState) => state.auth.token;
export const selectLogIn = (state: RootState) => state.auth.isLoggedIn;

export default authSlice.reducer;
