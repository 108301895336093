import { Box, Button } from "@mui/material";
import React, { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";

const btnStyle: CSSProperties = {
  margin: "10px",
  width: "100px",
  height: "50px",
  fontSize: "20px",
};

const Start = () => {
  const navigate = useNavigate();
  return (
    <Box display={"flex"} justifyContent="center">
      <Button
        sx={btnStyle}
        variant="contained"
        onClick={() => navigate("/mobile/dashboard", { replace: true })}
      >
        Mobile
      </Button>
      <Button
        sx={btnStyle}
        variant="contained"
        onClick={() => navigate("/desktop/dashboard", { replace: true })}
      >
        Desktop
      </Button>
    </Box>
  );
};

export default Start;
