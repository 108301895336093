import axios, { AxiosError } from "axios";
import {Buffer} from 'buffer';
import { config } from "../../config";
import { APIResponse } from "../types/api";
import { IFilter } from "../types/filter";
import { IOrderRequest, IOrder } from "../types/order";
import { IPhoto } from "../types/photo";
import { ISession } from "../types/session";

export const API_URL = `${config.API_URL}`;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${localStorage.getItem("jwt")}`;
  return config;
});

export async function apiService<T>(
  method: string,
  url: string,
  data?: any
): Promise<APIResponse<T>> {
  try {
    const res = await axios({
      method: method,
      url: `${API_URL}/api/v1/solar${url}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data as APIResponse<T>;
  } catch (error) {
    const err = error as AxiosError<APIResponse<T>>;
    return err.response?.data as APIResponse<T>;
  }
}

export async function VerifyToken(token: string): Promise<boolean> {
  const url = `${API_URL}/api/v1/oauth/token/${token}`;
  try {
    await axios.get(url);
    return true;
  } catch (error) {
    return false;
  }
}

export async function CreateOrder(
  order: IOrderRequest
): Promise<APIResponse<IOrder>> {
  return apiService<IOrder>("POST", "/order", order);
}

export async function UpdateOrder(
  order: IOrderRequest
): Promise<APIResponse<IOrder>> {
  return apiService<IOrder>("PATCH", "/order/" + order.id, order);
}

export async function GetOrder(id: string): Promise<APIResponse<IOrder>> {
  return apiService<IOrder>("GET", "/order/" + id);
}

export async function GetAllOrders(
  filter?: IFilter
): Promise<APIResponse<IOrder[]>> {
  let uri = "/order/all";
  if (filter) {
    uri += "?sort=" + filter.sort + "&order=" + filter.order;
  }
  return apiService<IOrder[]>("GET", uri);
}

export async function DeleteOrder(id: string): Promise<APIResponse<IOrder>> {
  return apiService<IOrder>("DELETE", "/order/" + id);
}

export async function DeletePhoto(id: string): Promise<APIResponse<null>> {
  return apiService("DELETE", "/photo/" + id);
}

export async function UploadFile<T>(
  url: string,
  data: any,
  onUploadProgress: any
): Promise<APIResponse<T>> {
  try {
    const response = await axios({
      method: "POST",
      url: url,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
    return response.data as APIResponse<T>;
  } catch (error) {
    const err = error as AxiosError<APIResponse<T>>;
    return err.response?.data as APIResponse<T>;
  }
}

export async function GetSession(
  email: string,
  password: string
): Promise<APIResponse<ISession | null>> {
  try {
    const res = await axios({
      method: "POST",
      url: `${API_URL}/api/v1/oauth/login`,
      data: {
        grant_type: "password",
        email: email,
        password: password,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
      state: res.status === 200 ? "success" : "error",
      message: "logged in",
    } as APIResponse<ISession>;
  } catch (error) {
    const err = error as AxiosError<APIResponse<null>>;
    const res = err.response;
    return {
      status: res?.data.status ?? 400,
      statusText: res?.data.statusText ?? "Bad Request",
      state: res?.data.status === 200 ? "success" : "error",
      message: res?.data.message ?? "error",
    } as APIResponse<null>;
  }
}

export function calculateRemainingTime(expTime: string): number {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
}

export async function GetImageBlob(url: string): Promise<string> {
  const res = await axios({
    url: url,
    method: "GET",
    responseType: "arraybuffer",
  });

  return `data:${res.headers["content-type"]};base64,${Buffer.from(res.data, "binary").toString("base64")}`;
}

export async function ServePhotoPreview(id:string): Promise<string>  {
  return GetImageBlob(`${API_URL}/api/v1/solar/photo?preview=true&id=${id}`);
}

export async function ServePhoto(id:string): Promise<string>  {
  return GetImageBlob(`${API_URL}/api/v1/solar/photo?id=${id}`);
}