import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Dashboard from "./app/desktop/Dashboard";
import DesktopLayout from "./app/desktop/Layout";
import OrderTable from "./app/order/OrderTable";
import MobileDashboard from "./app/mobile/Dashboard";
import MobileLayout from "./app/mobile/MobileLayout";
import Order from "./app/order/Order";
import Login from "./app/pages/Login";
import NewOrder from "./app/order/NewOrder";
import Start from "./app/pages/Start";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthProvider } from "./context/AuthProvider";
import { selectLogIn } from "./features/auth/authSlice";
import { useAuth } from "./hooks/useAuth";
import { solarService } from "./solar-api/service";
import { useAppDispatch, useAppSelector } from "./store/hooks";

function App() {
  
  const [loading, setLoading] = useState(false);

  const { onLogout, verifySession } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  async function checkSession() {
    setLoading(true);

    const lastLocation = location.pathname;
    const isValid = await verifySession();
    if (isValid) {
      if (lastLocation !== "/login" && lastLocation !== "/") {
        navigate(lastLocation, { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    } else {
      onLogout();
    }
    setLoading(false);
  }

  useEffect(() => {
    checkSession();
  }, []);
  return (
    <>
      {loading ? (
        <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className="App">
          <Routes>
            <Route index element={<Start />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/desktop"
              element={
                <ProtectedRoute>
                  <DesktopLayout />
                </ProtectedRoute>
              }
            >
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="orders" element={<OrderTable />} />
              <Route path="orders/:id" element={<Order />} />
            </Route>
            <Route
              path="/mobile"
              element={
                <ProtectedRoute>
                  <MobileLayout />
                </ProtectedRoute>
              }
            >
              <Route path="dashboard" element={<MobileDashboard />} />
              <Route path="orders" element={<OrderTable />} />
              <Route path="orders/:id" element={<Order />} />
            </Route>
              <Route path="/mobile/new-order" element={<NewOrder />} />
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;
