

export function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number
  ): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout> | undefined;
  
    return function (this: any, ...args: Parameters<T>): NodeJS.Timeout {
      const context = this;
  
      clearTimeout(timeout);
  
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);

      return timeout;
    };
  }