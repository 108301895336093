import React from 'react';

import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';


interface IProtectedRouteProps {
    children: JSX.Element;
}

export const ProtectedRoute = ({ children }: IProtectedRouteProps): JSX.Element => {
    const { token, verifySession } = useAuth();
  
    if (!token) {
      return <Navigate to="/login" replace />;
    }
  
    return children;
  };