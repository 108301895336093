import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { solarService } from "../../solar-api/service";
import { ICustomer, IOrderRequest, IOrder } from "../../solar-api/types/order";
import { RootState } from "../../store/store";

export interface OrderState {
  orders: IOrder[];
  currentOrder: IOrder | null;
  isInit: boolean;
  status: "idle" | "loading" | "failed";
  timeoutId: string;
}

const initialState: OrderState = {
  orders: [],
  isInit: false,
  currentOrder: null,
  status: "idle",
  timeoutId: ""
};

export const createOrder = createAsyncThunk(
  "order/create",
  async (order: IOrderRequest, { rejectWithValue }) => {
    const response = await solarService.CreateOrder(order);
    if (response.state === "error") {
      return rejectWithValue(response.message)
    }
    return response.data;
  }
);

export const UpdateOrder = createAsyncThunk(
  "order/update",
  async (order: IOrderRequest, { rejectWithValue }) => {
    const response = await solarService.UpdateOrder(order);
    if (response.state === "error") {
      return rejectWithValue(response.message)
    }
    return response.data;
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    initOrder: (state) => {
      state.isInit = true
    },
    deInitOrder: (state) => {
      localStorage.removeItem("current_order");
      state.isInit = false
    },
    setOrder: (state,action: PayloadAction<IOrder | null>) => {
      state.currentOrder = action.payload;
    },
    setCustomer: (state,action: PayloadAction<ICustomer | null>) => {
      let order = state.currentOrder;
      if (order) {
        order.customer = {
          ...action.payload
        } as ICustomer;
        state.currentOrder = order;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) state.currentOrder = action.payload;
      })
      .addCase(createOrder.rejected, (state) => {
        state.status = "failed";
      })
      // Update Order
      .addCase(UpdateOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(UpdateOrder.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) state.currentOrder = action.payload;
      })
      .addCase(UpdateOrder.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setOrder, setCustomer, initOrder, deInitOrder } = orderSlice.actions;

export const selectCurrentOrder = (state: RootState) => state.order.currentOrder;
export const selectCurrentCustomer = (state: RootState) => state.order.currentOrder?.customer;
export const selectOrders = (state: RootState) => state.order.orders;
export const selectOrderStatus = (state: RootState) => state.order.status;

export default orderSlice.reducer;
