import {
  Avatar,
  Box,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useUpload } from "../hooks/useUpload";
import { IPhoto } from "../../solar-api/types/photo";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import { solarService } from "../../solar-api/service";
import DecisionDialog from "./DecisionDialog";
import { config } from "../../config";

const fabStyle = {
  position: "absolute",
  zIndex: 1,
  bottom: 50,
  left: 0,
  right: 0,
  margin: "0 auto",
};

interface IImagaeSorcues {
  [key: string]: string
}

interface INewObjectPhotosProps {
  orderNumber: string;
  photos: IPhoto[];
  photos_object: string[];
  comment_object: string;
  onAddPhoto: (photo: IPhoto) => void;
  onDeletePhoto: (photo: IPhoto) => void;
  onChangeComment: (comment_object: string) => void;
}


const NewObjectPhotos = (props: INewObjectPhotosProps) => {
  const { upload, progress } = useUpload();
  const [imageSources, setImageSources] = useState<IImagaeSorcues>({});

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<IPhoto>();

  async function getPhotoSrc() {
    for (const p of props.photos) {
      try {
        const pUrl = await solarService.GetImageBlob(
          `${config.API_URL}${p.uri}`
        );
        setImageSources({
          ...imageSources,
          [p.id]: pUrl,
        });
      } catch (error) {
        console.log("Fehler bei der Erstellung der Foto URL");
        console.log(error);
      }
    }
  }

  useEffect(() => {
    getPhotoSrc();
  }, [props.photos]);


  async function handleUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const res = await upload(event, props.orderNumber);
    if (res.data) props.onAddPhoto(res.data);
  }

  function handleOpenDeleteDialog(photo: IPhoto){
    setSelectedPhoto(photo);
    setDialogOpen(true);
  }

  function handleCloseDeleteDialog(){
    setSelectedPhoto(undefined);
    setDialogOpen(false);
  }

  async function handleDeletePhoto() {
    if (!selectedPhoto) return;
    props.onDeletePhoto(selectedPhoto)
    setDialogOpen(false);
  }

  return (
    <div>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 2, width: "50ch" },
        }}
        noValidate
        autoComplete="off"
        display={"flex"}
        justifyContent={"center"}
        width={"100%"}
        mt={2}
      >
        <TextField
          label="Angaben zum Objekt"
          id="comment-object"
          value={props.comment_object}
          onChange={(e) => props.onChangeComment(e.target.value)}
          multiline
          variant="filled"
          rows={4}
        />
      </Box>
      <Box>
        <List>
          {props.photos.map((p, index) => {
            return (
              <ListItem key={index}>
                <ListItemButton>
                  <ListItemAvatar>
                  <Avatar alt={p.file.name} src={imageSources[p.id]}
                    sx={{
                      width: 56,
                      height: 56
                    }}
                  />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      ml:2
                    }}
                    primary={p.file.name.substring(0, 14) + "..."}
                  />
                </ListItemButton>
                <IconButton onClick={() => handleOpenDeleteDialog(p)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Fab component="label" color="secondary" size="large" sx={fabStyle}>
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={(e) => handleUpload(e)}
        />
        <PhotoCamera />
      </Fab>
      <Box sx={{ flexGrow: 1 }} />
      <DecisionDialog
        open={dialogOpen}
        title="Foto löschen"
        content="Bist du dir wirklich sicher Julian?"
        textOk="Löschen"
        textClose="Abbrechen"
        onClose={() => handleCloseDeleteDialog()}
        onOk={() => handleDeletePhoto()}
      />
    </div>
  );
};

export default NewObjectPhotos;
